import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('http://www.airox.com.tr/en/');
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
     
        <a href="http://www.airox.com.tr/en/">Airox</a>
      </p>
    </div>
  );
};
export default Home;